import { NodeKind } from 'Constants/graph'
import { TAG_COLOR_KIND } from 'Constants/tags'

import colors from './colors'
import oldColors from './oldColors'

export interface IGraph {
  nodeKind: Record<NodeKind, string | undefined>
  nodeKindLight: Partial<Record<NodeKind, string | undefined>>
}

const graph: IGraph = {
  nodeKindLight: {
    skill: '#F8D3B3',
    role: '#F2BCB9',
    project: '#BBF0EB',
    event: '#D5C1EA',
    group: '#BFEDD4',
    custom: '#F3B9D3',
    organization: '#B4DAF7',
    areaOfExperience: '#F8D3B3',
    explore: '#fb647b',
  },
  nodeKind: {
    user: oldColors.primaryCardinal,
    skill: colors.tag[TAG_COLOR_KIND.SKILL].graph,
    role: colors.tag[TAG_COLOR_KIND.ROLE].graph,
    project: colors.tag[TAG_COLOR_KIND.PROJECT].graph,
    event: colors.tag[TAG_COLOR_KIND.EVENT].graph,
    group: colors.tag[TAG_COLOR_KIND.GROUP].graph,
    custom: colors.tag[TAG_COLOR_KIND.CUSTOM].graph,
    organization: colors.tag[TAG_COLOR_KIND.ORGANIZATION].graph,
    community: colors.tag[TAG_COLOR_KIND.COMMUNITY].graph,
    knowledge: '#50A9EE',
    areaOfExperience: colors.tag[TAG_COLOR_KIND.SKILL].graph,
    explore: '#ff0000',
    exploreQuery: '#ff0000',
  },
}

export default graph
