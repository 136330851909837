import { Link } from 'regraph'

import { ItemType } from 'Constants/graph'

import { IEdgeData, IItemData } from './types'

export function createEdgeId(id1: string, id2: string) {
  return `edge:${id1}:${id2}`
}

export function createEdge({
  edgeData,
  label,
  directed,
  color = '#2D2D2D',
  fade = true,
}: {
  edgeData: IEdgeData
  label?: string
  directed?: boolean
  color?: string
  fade?: boolean
}): Link<IItemData<IEdgeData>> {
  const id = createEdgeId(edgeData.fromId, edgeData.toId)

  return {
    id1: edgeData.fromId,
    id2: edgeData.toId,
    lineStyle: 'solid',
    end2: {
      arrow: directed,
    },
    width: 0.6,
    color,
    fade,
    data: {
      id,
      type: ItemType.Edge,
      data: edgeData,
    },
    ...(label
      ? {
          label: {
            fontFamily: 'Inter',
            color: '#2D2D2D',
            text: label,
          },
        }
      : {}),
  }
}
