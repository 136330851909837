import { Node } from 'regraph'

import { IItemData } from 'Components/Blocks/Graph/utils'

import { ItemType } from 'Constants/graph'

import { IExploreNode } from './ExploreQuery.types'

export default function createExploreNode({
  exploreNode,
  isSelected = false,
}: {
  exploreNode: IExploreNode
  isSelected: boolean
}): Node<IItemData<IExploreNode>> {
  const nodeSize = 0.5

  return {
    data: {
      id: exploreNode.id,
      type: ItemType.Explore,
      data: exploreNode,
      entity: exploreNode,
    },
    shape: 'circle',
    cutout: true,
    image: `/img/nodes/explore2.svg`,
    size: nodeSize,
    label: [
      {
        backgroundColor: 'transparent',
        margin: {
          top: 50,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: exploreNode.label,
        textWrap: 'normal',
        minWidth: 100,
        position: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      },
    ],
    halos: [
      {
        color: isSelected ? 'rgba(251, 165, 98, 0.3)' : 'rgb(235, 236, 237)',
        radius: 28,
        width: isSelected ? 8 : 4,
      },
    ],
  }
}
