import React, {
  DragEvent,
  DragEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { IPinnedNode } from 'Features/PinnedNodes/usePinnedNodes'
import { Chart, Index, Items, Link, Node } from 'regraph'

import difference from 'lodash/difference'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import union from 'lodash/union'

import utils, {
  AnalyzerFunction,
  IItemData,
} from 'Components/Blocks/Graph/utils'

import { NodeKind, SkillTagKind } from 'Constants/graph'
import { UserRelationshipStrength } from 'Constants/ids'

import { useAppContext, useCommunityContext, useEventBusSubscribe } from 'Hooks'
import {
  IGraphMapper,
  IGraphState,
  IShowGraphMenu,
  ISubGraph,
  LoadGraphState,
} from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

import { DEFAULT_POSITIONS } from './constants'

export interface IUseGraphMapper {
  initialLayout: Chart.LayoutOptions
  nodes: Items<IItemData>
  edges: Index<Link<IItemData>>
  currentAnalyzer: AnalyzerFunction | null
  showRelationshipStrength: Record<string, boolean>
  targetUser?: IGraphPersonNode
  memoizedRelationshipEdges: Record<
    string,
    Record<string, UserRelationshipStrength>
  >
  paths: IGraphPersonNode[][]
  subGraphs: ISubGraph[]
  graphState: IGraphState
  isFilteringByRelationship: boolean
  targetUserNodes: Index<Node<IItemData>>
  targetUserEdges: Index<Link<IItemData>>
  myUserNodes: Index<Node<IItemData>>
  myUserEdges: Index<Link<IItemData>>
  savedLoadGraphState?: LoadGraphState
  onSetNodes: React.Dispatch<React.SetStateAction<Items<IItemData>>>
  onSetEdges: React.Dispatch<React.SetStateAction<Index<Link<IItemData>>>>
  onSetCurrentAnalyzer: React.Dispatch<
    React.SetStateAction<AnalyzerFunction | null>
  >
  onSetShowRelationshipStrength: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
  onSetSubGraphs: React.Dispatch<React.SetStateAction<ISubGraph[]>>
  onSetGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  onSetShowGraphMenu: React.Dispatch<
    React.SetStateAction<IShowGraphMenu | null>
  >
  onSetContextMenuIds: React.Dispatch<React.SetStateAction<string[]>>
  setClusteringEnabled: React.Dispatch<React.SetStateAction<boolean>>
  setShowRelationshipStrength: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
  pinnedNodes: IPinnedNode[]
  onSetPinnedNodes: React.Dispatch<React.SetStateAction<IPinnedNode[]>>
}

// Only mapping and filtering for creating final items object for Regraph
export default function useGraphMapper({
  initialLayout,
  nodes,
  edges,
  currentAnalyzer,
  showRelationshipStrength,
  targetUser,
  memoizedRelationshipEdges,
  paths,
  subGraphs,
  graphState,
  isFilteringByRelationship,
  targetUserNodes,
  targetUserEdges,
  myUserNodes,
  myUserEdges,
  savedLoadGraphState,
  onSetNodes,
  onSetEdges,
  onSetCurrentAnalyzer,
  onSetShowRelationshipStrength,
  onSetSubGraphs,
  onSetGraphState,
  onSetShowGraphMenu,
  onSetContextMenuIds,
  setShowRelationshipStrength,
  pinnedNodes,
  onSetPinnedNodes,
}: IUseGraphMapper): IGraphMapper {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const [previousSelectedIds, setPreviousSelectedIds] = useState<string[]>([])

  const selectedIds = useMemo(
    () => keys(graphState.selection),
    [graphState.selection],
  )

  const handleRemoveItemIds = useCallback(
    (ids: string[]) => {
      onSetNodes(omit(nodes, ids))
    },
    [nodes, onSetNodes],
  )

  const handleAppendItems = useCallback<IGraphMapper['handleAppendItems']>(
    ({
      users,
      skills,
      needSkills,
      tags,
      organizations,
      knowledge,
      communities,
      areasOfExperience,
      exploreQuery,
      explore,
    }) => {
      const [userNodes, userEdges] = utils.appendItems({
        myUserId: me?.userId,
        users,
        skills,
        needSkills,
        tags,
        organizations,
        knowledge,
        communities,
        areasOfExperience,
        existingItems: graphState.items,
        selectedIds,
        exploreQuery,
        explore,
      })
      onSetNodes(prevState => ({ ...prevState, ...userNodes }))
      onSetEdges(prevState => ({ ...prevState, ...userEdges }))
      return { userNodes, userEdges }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, onSetEdges, onSetNodes, selectedIds],
  )

  const handleLoadGraphState = useCallback<
    IGraphMapper['handleLoadGraphState']
  >(
    ({
      clusteringEnabled,
      showRelationshipStrength,
      analyzerFunction,
      nodes,
      edges,
      layout,
      view,
      positions,
      selection,
      combine,
      openCombos,
    }) => {
      onSetNodes(nodes)
      onSetEdges(edges)

      onSetSubGraphs([])

      onSetGraphState(prevState => ({
        ...prevState,
        appendItems: [],
        items: { ...nodes, ...edges },
        layout: layout ?? initialLayout,
        view: view ?? undefined,
        positions,
        selection,
        combine,
        openCombos,
        clusteringEnabled,
        analyzerFunction,
        showRelationshipStrength,
      }))

      handleAppendItems({
        users: pinnedNodes.map(node => node.entity as IGraphPersonNode),
      })
      onSetCurrentAnalyzer(analyzerFunction)
      setShowRelationshipStrength(showRelationshipStrength)
    },
    [
      onSetCurrentAnalyzer,
      onSetEdges,
      onSetNodes,
      onSetSubGraphs,
      onSetGraphState,
      setShowRelationshipStrength,
      initialLayout,
      pinnedNodes,
      handleAppendItems,
    ],
  )

  const handleGraphReset = useCallback<IGraphMapper['handleGraphReset']>(() => {
    handleLoadGraphState(
      savedLoadGraphState ?? {
        nodes: { ...targetUserNodes, ...myUserNodes },
        edges: { ...targetUserEdges, ...myUserEdges },
        selection: {},
        positions: DEFAULT_POSITIONS,
        analyzerFunction: null,
        clusteringEnabled: false,
        showRelationshipStrength: {},
      },
    )
  }, [
    handleLoadGraphState,
    savedLoadGraphState,
    myUserEdges,
    myUserNodes,
    targetUserEdges,
    targetUserNodes,
  ])

  const handleCreateSubGraph = useCallback(
    ({ subGraph }: { subGraph: Items }) => {
      const currentGraph = subGraphs
      currentGraph.push({
        savedNodes: nodes,
        subGraph,
        positions: DEFAULT_POSITIONS,
      })
      onSetSubGraphs(currentGraph)
      onSetNodes(subGraph)
    },
    [nodes, onSetNodes, onSetSubGraphs, subGraphs],
  )

  const handleFilterSelected = useCallback(() => {
    const basicItems = { ...nodes, ...edges }
    const subGraph = pickBy(
      basicItems,
      (_, id) => !utils.isEdge(id) && has(graphState.selection, id),
    )
    handleCreateSubGraph({ subGraph })
  }, [edges, handleCreateSubGraph, nodes, graphState.selection])

  const handleExitingSubGraph = useCallback(() => {
    const { savedNodes, subGraph, positions: lastPositions } = subGraphs.pop()!

    onSetSubGraphs(subGraphs)

    onSetNodes(savedNodes || subGraph)

    return lastPositions
  }, [onSetNodes, onSetSubGraphs, subGraphs])

  const handleChangeAnalyzer = useCallback(
    (analyzer: AnalyzerFunction | null) => {
      onSetCurrentAnalyzer(analyzer)
      onSetGraphState(prevState => ({
        ...prevState,
        forceLayoutReset: true,
      }))
    },
    [onSetCurrentAnalyzer, onSetGraphState],
  )

  const handleSetRelationshipStrength = useCallback<
    IGraphMapper['handleSetRelationshipStrength']
  >(
    ({ strength, enabled }) => {
      onSetShowRelationshipStrength(prevState => ({
        ...prevState,
        [strength]: enabled,
      }))
    },
    [onSetShowRelationshipStrength],
  )

  const handleAddSkillsTags = useCallback(
    ({
      id,
      name,
      kind,
      userId = targetUser?.userId,
    }: {
      id: string
      name: string
      kind: SkillTagKind
      userId?: string
    }) => {
      if (!userId) {
        return
      }

      const [node, edge] = utils.appendSkillTag({
        targetUserId: userId,
        id,
        name,
        kind,
        isSelected: selectedIds.includes(id),
      })
      onSetNodes(prevState => ({ ...prevState, ...{ ...node } }))
      onSetEdges(prevState => ({ ...prevState, ...{ ...edge } }))
    },
    [onSetEdges, onSetNodes, targetUser, selectedIds],
  )

  const handleConnectUser = useCallback(
    ({
      user,
      connectTo,
    }: {
      user: IGraphPersonNode
      connectTo: IGraphPersonNode
    }) => {
      const edge = utils.createUserConnection({
        user,
        connectTo,
      })

      onSetEdges(prevState => ({ ...prevState, ...edge }))
    },
    [onSetEdges],
  )

  const handleTemporaryConnectUser = useCallback(
    ({ fromUserId, toUserId }: { fromUserId: string; toUserId: string }) => {
      const edge = utils.createTemporaryUserConnection({
        fromUserId,
        toUserId,
      })

      onSetEdges(prevState => ({ ...prevState, ...edge }))
    },
    [onSetEdges],
  )

  const handleConnectSkillTag = useCallback(
    ({
      fromId,
      toId,
      kind,
    }: {
      fromId: string
      toId: string
      kind: NodeKind
    }) => {
      const edge = utils.appendUserSkillTagEdge({
        toId,
        fromId,
        kind,
      })
      onSetEdges(prevState => ({ ...prevState, ...edge }))
    },
    [onSetEdges],
  )

  const handleDisconnectSkillTag = useCallback(
    ({ fromId, toId }: { fromId: string; toId: string }) => {
      const edge = utils.findExistingEdge(edges, toId, fromId)
      if (edge?.data?.id) {
        onSetEdges(prevState => {
          const newState = { ...prevState }
          delete newState[edge?.data?.id!]
          return newState
        })
      }
    },
    [edges, onSetEdges],
  )

  // TODO: All these connects can be merged into a single handler, they all do the exact same thing.
  // All these utils.appendXXXEdges can also be merged. We just need to keep the nodeKind (maybe?)
  const handleConnectCommunity = useCallback(
    ({
      fromId,
      toId,
      kind,
    }: {
      fromId: string
      toId: string
      kind: NodeKind
    }) => {
      const edge = utils.appendCommunityEdge({
        toId,
        fromId,
        kind,
      })
      onSetEdges(prevState => ({ ...prevState, ...edge }))
    },
    [onSetEdges],
  )

  const handleExitSubGraph = useCallback(() => {
    onSetGraphState(prevState => ({
      ...prevState,
      positions: handleExitingSubGraph(),
    }))
  }, [handleExitingSubGraph, onSetGraphState])

  interface ICustomDragUserEvent extends DragEvent<HTMLDivElement> {
    user: string
  }

  const handleExternalDragDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    event => {
      const customEvent = event as ICustomDragUserEvent

      // load the target user into the graph first, so pathing doesn't overwrite the node
      EventBus.trigger(EventBus.actions.graph.search, {
        limit: 1,
        users: [customEvent.user],
      })

      // Now search for paths for target
      // TODO: Rename event.user to event.userId
      EventBus.trigger(EventBus.actions.graph.findPath, customEvent.user)
    },
    [],
  )

  const handleSubgraphSelectedItems = useCallback(() => {
    handleFilterSelected()
    onSetShowGraphMenu(null)
    onSetContextMenuIds([])
  }, [handleFilterSelected, onSetShowGraphMenu, onSetContextMenuIds])

  const handleRemoveSelected = useCallback(() => {
    handleRemoveItemIds(keys(graphState.selection))

    onSetGraphState(prevState => ({ ...prevState, selection: {} }))

    onSetShowGraphMenu(null)
    onSetContextMenuIds([])
  }, [
    graphState.selection,
    handleRemoveItemIds,
    onSetGraphState,
    onSetShowGraphMenu,
    onSetContextMenuIds,
  ])

  const handleDisconnectUser = useCallback(
    ({ fromId, toId }: { fromId: string; toId: string }) => {
      const edge = utils.findExistingEdge(graphState.items, fromId, toId)
      if (edge) {
        onSetEdges(prevState => ({
          ...omit(prevState, edge.data!.id),
        }))
      }
    },
    [graphState.items, onSetEdges],
  )

  const handlePinToGraph = useCallback(
    (nodes: IPinnedNode[]) => {
      onSetPinnedNodes(prevState => [...prevState, ...nodes])
      onSetGraphState(prevState => ({
        ...prevState,
        selection: {},
      }))
    },
    [onSetGraphState, onSetPinnedNodes],
  )

  const handleRemovePin = useCallback(
    (nodes: MainSchema.CommunityUser[]) => {
      onSetPinnedNodes(prevState =>
        prevState.filter(
          user => !nodes.find(node => user.entityId === node.userId),
        ),
      )
      onSetGraphState(prevState => ({
        ...prevState,
        selection: {},
      }))
    },
    [onSetGraphState, onSetPinnedNodes],
  )

  useEventBusSubscribe(
    EventBus.actions.graph.viewSelected,
    handleSubgraphSelectedItems,
  )
  useEventBusSubscribe(EventBus.actions.graph.addSkillTags, handleAddSkillsTags)
  useEventBusSubscribe(EventBus.actions.graph.connectUser, handleConnectUser)
  useEventBusSubscribe(
    EventBus.actions.graph.removeSelected,
    handleRemoveSelected,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.connectSkillTag,
    handleConnectSkillTag,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.disconnectSkillTag,
    handleDisconnectSkillTag,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.removeItemsByIds,
    handleRemoveItemIds,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.disconnectUser,
    handleDisconnectUser,
  )

  useEventBusSubscribe(EventBus.actions.graph.pinToGraph, handlePinToGraph)

  useEventBusSubscribe(EventBus.actions.graph.removePin, handleRemovePin)

  useEventBusSubscribe(
    EventBus.actions.graph.addCommunityEdge,
    handleConnectCommunity,
  )

  // Once me and target user (could be me) are loaded, add them into the nodes/edges state
  useEffect(() => {
    onSetNodes(prevState => ({
      ...prevState,
      ...targetUserNodes,
      ...myUserNodes,
    }))

    onSetEdges(prevState => ({
      ...prevState,
      ...targetUserEdges,
      ...myUserEdges,
    }))
  }, [
    myUserEdges,
    myUserNodes,
    onSetEdges,
    onSetNodes,
    targetUserEdges,
    targetUserNodes,
  ])

  // TODO: This is a temporary work around to update the styles of nodes based on what is selected on the graph. Find a more more permanent solution
  useEffect(() => {
    const allSelectedIds = union(selectedIds, previousSelectedIds)
    const sameSelectedIds = intersection(selectedIds, previousSelectedIds)
    const differentSelectedIds = difference(allSelectedIds, sameSelectedIds)

    if (differentSelectedIds.length === 0) {
      return
    }

    const updatedNodes: Items<IItemData> = {}
    const items = { ...nodes, ...edges }

    forEach(differentSelectedIds, differentSelectedId => {
      const item = items[differentSelectedId]

      if (!item || !utils.isItemDataNode(item)) {
        return
      }

      const isSelected = selectedIds.includes(item.data!.id)

      if (utils.isUserNode(item)) {
        const userNode = utils.getAsUserNode(item)

        const updatedUserNode = utils.createUserNode({
          user: userNode.data!.data,
          isMe: userNode.data!.data.userId === me?.userId,
          isSelected,
        })

        updatedNodes[userNode.data!.id] = updatedUserNode
      } else if (utils.isSkillTagNode(item)) {
        const skillTagNode = utils.createSkillTagNode({
          skillTagData: item.data!.data,
          skillTag: item.data!.data,
          isSelected,
        })

        updatedNodes[skillTagNode.data!.id] = skillTagNode
      } else if (utils.isOrganizationNode(item)) {
        const organizationNode = utils.createOrganizationNode({
          organizationData: item.data!.data,
          organization: item.data!.data,
          isSelected,
        })

        updatedNodes[organizationNode.data!.id] = organizationNode
      } else if (utils.isKnowledgeNode(item)) {
        const knowledgeNode = utils.createKnowledgeNode({
          knowledgeNode: item.data!.data,
          isSelected,
        })

        updatedNodes[knowledgeNode.data!.id] = knowledgeNode
      } else if (utils.isAreaOfExperienceNode(item)) {
        const areaOfExperienceNode = utils.createAreaOfExperienceNode({
          areaOfExperienceNode: item.data!.data,
          isSelected,
        })

        updatedNodes[areaOfExperienceNode.data!.id] = areaOfExperienceNode
      }
    })

    onSetNodes(prevState => ({
      ...prevState,
      ...updatedNodes,
    }))

    setPreviousSelectedIds(selectedIds)
  }, [
    nodes,
    edges,
    previousSelectedIds,
    selectedIds,
    me,
    community?.id,
    onSetNodes,
    onSetEdges,
  ])

  // Handle new items coming into the graph, don't readd existing nodes
  useEffect(() => {
    function getNewItems() {
      if (!graphState.appendUsers || graphState.appendUsers?.length === 0) {
        return
      }

      const [appendedNodes, appendedEdges] = utils.appendItems({
        myUserId: me?.userId,
        users: graphState.appendUsers,
        existingItems: graphState.items,
        selectedIds,
      })

      onSetNodes(prevState => ({
        ...prevState,
        ...appendedNodes,
      }))

      onSetEdges(prevState => ({
        ...prevState,
        ...appendedEdges,
      }))

      onSetGraphState(prevState => ({
        ...prevState,
        appendUsers: [],
      }))
    }

    getNewItems()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphState.appendUsers, me, showRelationshipStrength, selectedIds])

  // Apply all filtering, relationship strength and analyzers into final "items" object for graph
  useEffect(() => {
    async function getItems() {
      if (!me) {
        return
      }

      const newItems = isFilteringByRelationship
        ? utils.appendRelationshipStrengthEdges({
            me,
            nodes,
            relationships: memoizedRelationshipEdges,
            showRelationshipStrength,
            edges,
          })
        : { ...nodes, ...edges }

      const pathingItems = isEmpty(paths)
        ? {}
        : utils.buildPaths(paths, newItems, me, selectedIds)

      const updatedItems =
        currentAnalyzer !== null
          ? await utils.analyzeNodes(currentAnalyzer, {
              ...newItems,
              ...pathingItems,
            })
          : { ...newItems, ...pathingItems }

      onSetGraphState(prevState => ({
        ...prevState,
        items: {
          ...updatedItems,
        },
        forceLayoutReset: false,
        showRelationshipStrength,
        // If appendUsers has changed, and it's a large quantity, we need to trigger layout to prevent graph lag
        positions:
          prevState.forceLayoutReset ||
          (graphState.appendUsers &&
            prevState.appendUsers !== graphState.appendUsers &&
            graphState.appendUsers.length > 300)
            ? DEFAULT_POSITIONS
            : prevState.positions,
        layout:
          prevState.forceLayoutReset ||
          (graphState.appendUsers &&
            prevState.appendUsers !== graphState.appendUsers &&
            graphState.appendUsers.length > 300)
            ? initialLayout
            : prevState.layout,
      }))
    }
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nodes,
    edges,
    paths,
    me,
    selectedIds,
    community?.id,
    currentAnalyzer,
    showRelationshipStrength,
    initialLayout,
  ])

  return {
    handleAppendItems,
    handleTemporaryConnectUser,
    handleChangeAnalyzer,
    handleGraphReset,
    handleLoadGraphState,
    handleExitSubGraph,
    handleExternalDragDrop,
    handleSubgraphSelectedItems,
    handleRemoveSelected,
    handleSetRelationshipStrength,
  }
}

/* Saving
  const handleGeneratePositions = useCallback((graphNodes, graphEdges) => {
    const itemsCount = Object.keys(graphNodes).length
    const bounds = {
      maxX: itemsCount,
      maxY: itemsCount,
      minX: -itemsCount,
      minY: -itemsCount,
    }
    const graphPositions = {}

    forEach(graphNodes, (node, nodeId) => {
      const nextX = random(bounds.minX, bounds.maxX)
      const nextY = random(bounds.minY, bounds.maxY)

      graphPositions[nodeId] = { x: nextX, y: nextY }
    })

    return graphPositions
  }, [])
  */
