import { Node } from 'regraph'

import { IItemData } from 'Components/Blocks/Graph/utils'

import { ItemType } from 'Constants/graph'

import { theme } from 'Theme'

import { IExploreQueryNode } from './ExploreQuery.types'

const DEFAULT_NODE_SIZE = 50

export default function createExploreQueryNode({
  exploreQueryNode,
  isSelected = false,
}: {
  exploreQueryNode: IExploreQueryNode
  isSelected: boolean
}): Node<IItemData<IExploreQueryNode>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8

  return {
    data: {
      id: exploreQueryNode.id,
      type: ItemType.Explore,
      data: exploreQueryNode,
      entity: exploreQueryNode,
    },
    color: isSelected ? '#ffffff' : '#000000',
    shape: 'circle',
    cutout: true,
    border: {
      color: isSelected ? '#ffffff' : 'rgb(251, 100, 123)',
      width: borderWidth,
    },

    halos: [
      {
        color: isSelected ? 'rgb(227, 90, 111)' : 'rgb(235, 236, 237)',
        radius: 28,
        width: isSelected ? 8 : 4,
      },
      {
        color: isSelected ? 'rgb(251, 100, 123)' : 'rgb(228, 229, 230)',
        radius: 32,
        width: isSelected ? 4 : 0,
      },
      {
        color: isSelected ? 'transparent' : 'rgb(228, 229, 230)',
        radius: 31,
        width: 2,
      },
    ],
    image: `/img/nodes/explore.svg`,
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected
          ? '#ffffff'
          : theme.graph.nodeKind[exploreQueryNode.kind],
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: exploreQueryNode.label,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    ],
  }
}
