import { StoreonModule } from 'storeon'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { CookieStorage } from 'Services/CookiePersist'
import { getStore, getWebSocketLink } from 'Services/Shared'

export const AUTH_STATE_NAMESPACE = 'auth'

enum AuthStateField {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export interface AuthState {
  [AUTH_STATE_NAMESPACE]: {
    [AuthStateField.AccessToken]?: string | null
    [AuthStateField.RefreshToken]?: string | null
  }
}

export const AUTH_TOKEN_DEFAULTS: AuthState[typeof AUTH_STATE_NAMESPACE] = {
  accessToken: null,
  refreshToken: null,
}

enum AuthStoreEvent {
  Set = `${AUTH_STATE_NAMESPACE}/set`,
  Clear = `${AUTH_STATE_NAMESPACE}/clear`,
}

export interface AuthStateEvents {
  [AuthStoreEvent.Set]: {
    [AuthStateField.AccessToken]?: string | null
    [AuthStateField.RefreshToken]?: string | null
  }
  [AuthStoreEvent.Clear]: undefined
}

export function getAuth() {
  return get(getStore().get(), AUTH_STATE_NAMESPACE)
}

export function getAccessToken() {
  return get(getAuth(), AuthStateField.AccessToken)
}

export function getRefreshToken() {
  return get(getAuth(), AuthStateField.RefreshToken)
}

export function setAuth(auth: AuthStateEvents[AuthStoreEvent.Set]) {
  return getStore().dispatch(AuthStoreEvent.Set, auth)
}

export function clearAuth() {
  return getStore().dispatch(AuthStoreEvent.Clear)
}

function notifyLinks(token?: string | null) {
  const webSocketLink = getWebSocketLink()

  if (webSocketLink) webSocketLink.onTokenChanged(token)
}

const authStateModule: StoreonModule<AuthState, AuthStateEvents> = store => {
  store.on('@init', () => ({
    [AUTH_STATE_NAMESPACE]: AUTH_TOKEN_DEFAULTS,
  }))

  store.on(AuthStoreEvent.Set, (state, variables) => {
    const values = pick(variables, Object.keys(AUTH_TOKEN_DEFAULTS))

    const currentAuth = state[AUTH_STATE_NAMESPACE]
    const nextAuth = { ...currentAuth, ...values }

    if (isEqual(nextAuth, currentAuth)) return null

    if (nextAuth?.accessToken !== currentAuth?.accessToken) {
      notifyLinks(nextAuth?.accessToken)
      if (nextAuth?.accessToken) {
        CookieStorage.setItem('accessToken', nextAuth.accessToken)
      }
    }

    if (
      nextAuth?.refreshToken !== currentAuth?.refreshToken &&
      nextAuth?.refreshToken
    ) {
      CookieStorage.setItem('refreshToken', nextAuth.refreshToken)
    }

    return { [AUTH_STATE_NAMESPACE]: nextAuth }
  })

  store.on(AuthStoreEvent.Clear, () => {
    // Clear cookies when auth state is cleared
    CookieStorage.removeItem('accessToken')
    CookieStorage.removeItem('refreshToken')

    return { [AUTH_STATE_NAMESPACE]: AUTH_TOKEN_DEFAULTS }
  })
}

export default authStateModule
